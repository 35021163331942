import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113')
];

export const server_loads = [0,2,5];

export const dictionary = {
		"/(public)": [72],
		"/(public)/about-us": [73],
		"/(public)/add-deck": [74],
		"/(admin)/admin/extra-mana/add": [~19,[2,3]],
		"/(admin)/admin/order_issue_report": [~20,[2]],
		"/(admin)/admin/order_issue_report/[id]": [~21,[2]],
		"/(admin)/admin/orders/unfulfilled": [~24,[2]],
		"/(admin)/admin/orders/[seller_order_id]": [~22,[2]],
		"/(admin)/admin/orders/[seller_order_id]/report": [~23,[2]],
		"/(admin)/admin/payouts/edit": [~25,[2,4]],
		"/(admin)/admin/payouts/history": [~26,[2,4]],
		"/(admin)/admin/payouts/issue": [~27,[2,4]],
		"/(admin)/admin/payouts/pending": [~28,[2,4]],
		"/(admin)/admin/payouts/seller/[normalized_username]": [~29,[2,4]],
		"/(admin)/admin/seller-stats": [~30,[2]],
		"/(admin)/admin/supply/health": [~31,[2]],
		"/(admin)/admin/supply/health/[format]": [~32,[2]],
		"/(public)/affiliates": [75],
		"/api/docs/ccgseller": [110],
		"/api/docs/v1": [111],
		"/auth": [~112],
		"/auth/confirm": [~113],
		"/(public)/browse_sealed": [76,[10]],
		"/(public)/cards": [79,[12]],
		"/(public)/cards/set/[set_code]/[[set_name]]": [80,[12,13]],
		"/(public)/card/[card_slug]": [77],
		"/(public)/card/[set_code]/[card_number]/[[card_name]]": [78,[11]],
		"/(public)/cart": [81,[14]],
		"/(public)/cart/checkout": [82,[14]],
		"/(public)/cart/optimizer": [83,[14]],
		"/(public)/deals": [84],
		"/(public)/mail": [85],
		"/(public)/mail/error": [87],
		"/(public)/mail/notfound": [88],
		"/(public)/mail/[template]/[content]": [~86],
		"/(public)/mtg": [89],
		"/(public)/mtg/set/[set_code]": [90],
		"/(public)/mtg/set/[set_code]/[slug]": [91],
		"/(public)/optimizer": [92],
		"/(authed)/packing-slip/[order_id]": [33,[5]],
		"/(public)/privacy": [93],
		"/(authed)/profile/change-email": [34,[5,6]],
		"/(authed)/profile/change-password": [35,[5,6]],
		"/(authed)/profile/notifications": [~36,[5,6]],
		"/(public)/racist-depictions-policy": [94],
		"/(public)/redirect-after-login": [~95],
		"/(public)/reset-password": [~96],
		"/(public)/return-policy": [97],
		"/(public)/sealed/[set_code]/[[slug]]": [98,[15]],
		"/(public)/seller-info": [99],
		"/(authed)/seller": [37,[5,7]],
		"/(authed)/seller/integrations": [38,[5,7,8]],
		"/(authed)/seller/integrations/ccgseller": [~39,[5,7,8]],
		"/(authed)/seller/integrations/crystalcommerce": [~40,[5,7,8]],
		"/(authed)/seller/integrations/manapool-api": [~41,[5,7,8]],
		"/(authed)/seller/integrations/shopify": [42,[5,7,8]],
		"/(authed)/seller/inventory": [43,[5,7]],
		"/(authed)/seller/inventory/add/[[item_id]]": [44,[5,7]],
		"/(authed)/seller/inventory/edit/[item_id]": [45,[5,7]],
		"/(authed)/seller/inventory/import": [~46,[5,7]],
		"/(authed)/seller/orders": [47,[5,7]],
		"/(authed)/seller/orders/fulfilled": [50,[5,7]],
		"/(authed)/seller/orders/open": [51,[5,7]],
		"/(authed)/seller/orders/[order_id]": [~48,[5,7]],
		"/(authed)/seller/orders/[order_id]/report": [~49,[5,7]],
		"/(authed)/seller/payouts": [~52,[5,7]],
		"/(authed)/seller/payouts/[payout_id]": [~53,[5,7]],
		"/(authed)/seller/profile": [54,[5,7]],
		"/(authed)/seller/sales": [55,[5,7]],
		"/(authed)/seller/sales/[id]": [56,[5,7]],
		"/(authed)/settings": [57,[5,9]],
		"/(authed)/settings/addresses": [58,[5,9]],
		"/(authed)/settings/affiliates": [59,[5,9]],
		"/(authed)/settings/billing": [60,[5,9]],
		"/(authed)/settings/change-email": [61,[5,9]],
		"/(authed)/settings/change-password": [62,[5,9]],
		"/(authed)/settings/condition": [63,[5,9]],
		"/(authed)/settings/extra-mana": [64,[5,9]],
		"/(authed)/settings/notifications": [65,[5,9]],
		"/(authed)/settings/orders": [66,[5,9]],
		"/(authed)/settings/orders/report/[order_id]": [~68,[5,9]],
		"/(authed)/settings/orders/suborder/[seller_order_id]": [~69,[5,9]],
		"/(authed)/settings/orders/[order_id]": [~67,[5,9]],
		"/(authed)/settings/referrals": [~70,[5,9]],
		"/(public)/set/all_sets": [101],
		"/(public)/set/[set_code]/[[slug]]": [100,[16]],
		"/(public)/shopify": [~103],
		"/(public)/shop/[normalized_username]": [102,[17,18]],
		"/(public)/signin": [~104],
		"/(public)/signup": [~105],
		"/(authed)/support": [~71,[5]],
		"/(public)/terms": [106],
		"/(public)/thank-you/[id]": [~107],
		"/(public)/top_cards": [~108],
		"/(public)/top_cards/[format]": [~109]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';